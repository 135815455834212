import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import Button from '../components/Button';

const IndexPage: FC<PageProps> = () => (
  <div className="flex flex-col justify-center">
    <div className="max-w-xl mx-auto mt-24">
      <div className="my-12 flex space-x-6 justify-center">
        {/* <Button color="primary" to="/inscription/" variant="outline"> */}
        {/*  S&apos;inscrire */}
        {/* </Button> */}
        <Button to="/login/">Se connecter</Button>
      </div>
    </div>
  </div>
);

export default IndexPage;
